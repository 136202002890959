.topbar {
  height: 50px;
  background-color: white;
  border-color: #080808;
  border-style: solid;
  border-width: 0px 0px 2px 0px;
  display: none;
}

.navbar {
  height: 74.5%;
  width: 18%;
  min-width: 250px;
  max-width: 600px;
  max-height: 1000px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  font-size: 1em;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.navbar-active {
  height: 74.5%;
  width: 18%;
  min-width: 250px;
  max-width: 600px;
  max-height: 1000px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  position: fixed;
  margin: auto;
  left: 3vw;
  right: 100vw;
  top: 0;
  bottom: 0;
  text-align: center;
  font-size: 1em;
  transition: 0.8s;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.navbar-row {
  font-family: "Jost", sans-serif;
  border-style: solid;
  border-width: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.navbar-row span {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.5s;
}

.navbar-row :hover {
  background-color: #080808;
  border-color: #080808;
  color: white;
}

.active {
  background-color: #080808;
  border-color: #080808;
  color: white;
}

.navbar-row-large {
  height: 20%;
  font-family: "Amatic SC", cursive;
  font-weight: 700;
  font-size: 3.2em;
}

.navbar-row-medium {
  font-size: 1.1em;
  height: 14%;
  font-family: "Raleway", sans-serif;
}

.navbar-row-small {
  height: 9.5%;
}

.navbar-row-small-left {
  width: 100%;
  height: 100%;
  display: inline;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.1s;
  transition: color 0.5s;
}

.navbar-row-small-right {
  width: 100%;
  height: 100%;
  display: inline;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.1s;
  transition: color 0.4s;
}

.link {
  text-decoration: none;
  color: #080808;
}

.link-small {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link-small-left {
  border-style: solid;
  border-width: 0px 1px 0px 0px;
}

.link-small-right {
  border-style: solid;
  border-width: 0px 0px 0px 1px;
}

@media (max-width: 1024px) {
  .topbar {
    display: inline-flex;
    width: 100%;
    align-items: center;
    z-index: 1;
    position: fixed;
    left: 0px;
    top: 0px;
    
  }
  
  .topbar-title {
    width: 100%;
    text-align: center;
    margin-right: clamp(1.5rem, 2vw + 1.1rem + 10px, 6rem);
    font-size: 1.1em;
    font-family: "Raleway", sans-serif;
  }

  .navbar {
    position: fixed;
    margin: 0;
    top: 50px;
    width: 100vw;
    max-width: 600vw;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .navbar-active {
    position: fixed;
    margin: 0;
    top: 50px;
    left: 0;
    right:0;
    width: 100vw;
    max-width: 600vw;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .close {
    /* display: none; */
    transform: translateY(-110%)
  }

  .hamburger {
    margin-left: 10px;
    cursor: pointer;
    transition: opacity 0.25s linear;
    width: clamp(1.5rem, 2vw + 1.1rem, 6rem);
    height: clamp(2rem, 1.4vw + 1.7rem, 5rem);
    display: flex;
    align-items: center;
  }

  .hamburger div,
  .hamburger div:after,
  .hamburger div:before {
    background-color: #080808;
    border-radius: 10px;
    width: clamp(1.5rem, 2vw + 1.1rem, 6rem);
    height: clamp(0.2rem, 0.3vw + 0.1rem, 0.8rem);
    transition: all 0.15s linear;
  }

  .hamburger div:before,
  .hamburger div:after {
    content: "";
    position: absolute;
  }

  .hamburger div:before {
    transform: translateY(-200%);
  }

  .hamburger div:after {
    transform: translateY(200%);
  }

  .hamburger.open div {
    background: transparent;
  }

  .hamburger.open div:before {
    transform: rotate(45deg);
  }

  .hamburger.open div:after {
    transform: rotate(-45deg);
  }
}
