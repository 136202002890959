@import url(https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Jost&family=Raleway:wght@200;300;400;500;600;700&display=swap);
/* @import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Jost&family=Raleway:wght@600&display=swap"); */

.App {
  width: 100%;
  height: 100vh;
  background-color: white;
}

body {
  margin: 0;
  padding: 0;
}

.content {
  width: 75%;
  float: right;
  text-align: center;
  padding-left: 2em;
  padding-right: 2em;
}

@media (max-width: 1024px) {
  .content {
    width: 90%;
    float: none;
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
    /* margin-top: 50px; */
    margin-left: auto;
    margin-right: auto;
  }
}

.continutprovizoriu {
  /* position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center; */
}

.topbar {
  height: 50px;
  background-color: white;
  border-color: #080808;
  border-style: solid;
  border-width: 0px 0px 2px 0px;
  display: none;
}

.navbar {
  height: 74.5%;
  width: 18%;
  min-width: 250px;
  max-width: 600px;
  max-height: 1000px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  font-size: 1em; /* Safari/Chrome, other WebKit */ /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.navbar-active {
  height: 74.5%;
  width: 18%;
  min-width: 250px;
  max-width: 600px;
  max-height: 1000px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  position: fixed;
  margin: auto;
  left: 3vw;
  right: 100vw;
  top: 0;
  bottom: 0;
  text-align: center;
  font-size: 1em;
  transition: 0.8s; /* Safari/Chrome, other WebKit */ /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.navbar-row {
  font-family: "Jost", sans-serif;
  border-style: solid;
  border-width: 1px;
  display: flex;
  justify-content: center;
  align-items: center; /* Safari/Chrome, other WebKit */ /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.navbar-row span {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.5s;
}

.navbar-row :hover {
  background-color: #080808;
  border-color: #080808;
  color: white;
}

.active {
  background-color: #080808;
  border-color: #080808;
  color: white;
}

.navbar-row-large {
  height: 20%;
  font-family: "Amatic SC", cursive;
  font-weight: 700;
  font-size: 3.2em;
}

.navbar-row-medium {
  font-size: 1.1em;
  height: 14%;
  font-family: "Raleway", sans-serif;
}

.navbar-row-small {
  height: 9.5%;
}

.navbar-row-small-left {
  width: 100%;
  height: 100%;
  display: inline;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.1s;
  transition: color 0.5s;
}

.navbar-row-small-right {
  width: 100%;
  height: 100%;
  display: inline;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.1s;
  transition: color 0.4s;
}

.link {
  text-decoration: none;
  color: #080808;
}

.link-small {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link-small-left {
  border-style: solid;
  border-width: 0px 1px 0px 0px;
}

.link-small-right {
  border-style: solid;
  border-width: 0px 0px 0px 1px;
}

@media (max-width: 1024px) {
  .topbar {
    display: inline-flex;
    width: 100%;
    align-items: center;
    z-index: 1;
    position: fixed;
    left: 0px;
    top: 0px;
    
  }
  
  .topbar-title {
    width: 100%;
    text-align: center;
    margin-right: clamp(1.5rem, 2vw + 1.1rem + 10px, 6rem);
    font-size: 1.1em;
    font-family: "Raleway", sans-serif;
  }

  .navbar {
    position: fixed;
    margin: 0;
    top: 50px;
    width: 100vw;
    max-width: 600vw;
    box-sizing: border-box;
  }

  .navbar-active {
    position: fixed;
    margin: 0;
    top: 50px;
    left: 0;
    right:0;
    width: 100vw;
    max-width: 600vw;
    box-sizing: border-box;
  }

  .close {
    /* display: none; */
    transform: translateY(-110%)
  }

  .hamburger {
    margin-left: 10px;
    cursor: pointer;
    transition: opacity 0.25s linear;
    width: clamp(1.5rem, 2vw + 1.1rem, 6rem);
    height: clamp(2rem, 1.4vw + 1.7rem, 5rem);
    display: flex;
    align-items: center;
  }

  .hamburger div,
  .hamburger div:after,
  .hamburger div:before {
    background-color: #080808;
    border-radius: 10px;
    width: clamp(1.5rem, 2vw + 1.1rem, 6rem);
    height: clamp(0.2rem, 0.3vw + 0.1rem, 0.8rem);
    transition: all 0.15s linear;
  }

  .hamburger div:before,
  .hamburger div:after {
    content: "";
    position: absolute;
  }

  .hamburger div:before {
    transform: translateY(-200%);
  }

  .hamburger div:after {
    transform: translateY(200%);
  }

  .hamburger.open div {
    background: transparent;
  }

  .hamburger.open div:before {
    transform: rotate(45deg);
  }

  .hamburger.open div:after {
    transform: rotate(-45deg);
  }
}

.certifications-page {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.certifications-card {
  width: 500px;
  max-width: 100%;
  border-color: #080808;
  border-style: solid;
  border-width: 2px; /* Safari/Chrome, other WebKit */ /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.certifications-cart-top-section {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.certifications-title {
  font-family: "Amatic SC", cursive;
  font-weight: 700;
  font-size: 3.2em;
  margin: 0px;
  text-align: left;
}

.certifications-cart-bottom-section {
  margin: 0px 20px 20px 20px;
  font-family: "Raleway", sans-serif;
}

.certifications-certificate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.certifications-certificate-titles {
  font-size: 1em;
  font-weight: 600;
  
}
.certifications-certificate-title {
  margin: 0px;
  display: flex;
  flex-direction: row;
  text-align: left;
}
.certifications-certificate-level {
  font-size: 0.9em;
}

.certifications-certificate-issuer {
  display: flex;
  flex-direction: row;
  text-align: left;
  font-size: 0.9em;
  margin: 0px 0px 7px 1em;
}
@media (max-width: 1024px) {
  .certifications-card{
    margin-top: 60px;
    margin-bottom: 40px;
  }
  .certifications-cart-top-section {
    flex-direction: column;
    justify-content: center;
  }
  .certifications-title {
    margin-left: auto;
    margin-right: auto;
  }
  .certifications-title-row {
    text-align: center;
  }
}

.contact-page {
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .contact-card {
    width: 330px;
    max-width: 100%;
    border-color: #080808;
    border-style: solid;
    border-width: 2px; /* Safari/Chrome, other WebKit */ /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .contact-cart-top-section {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .contact-title {
    font-family: "Amatic SC", cursive;
    font-weight: 700;
    font-size: 3.2em;
    margin: 0px;
    text-align: left;
  }
  
  .contact-cart-bottom-section {
    margin: 0px 0px 40px 0px;
    text-align: justify;
    font-size: 1.1em;
    font-family: "Raleway", sans-serif;
  }

  .contact-cart-bottom-section > div {
    text-align: center;
    margin-top: 12px;
    font-weight: 300;

  }

  .contact-info {
    font-weight: 500;
  }
  
  @media (max-width: 1024px) {
    .contact-cart-top-section {
      flex-direction: column;
      justify-content: center;
    }
    .contact-title {
      margin-left: auto;
      margin-right: auto;
    }
    .contact-title-row {
      text-align: center;
    }
  }
  
.about-page {
  background-color: white;
  min-height: 100vh;
  display:flex;
  align-items: center;
}

.about-card {
  width: 650px;
  max-width: 100%;
  border-color: #080808;
  border-style: solid;
  border-width: 2px; /* Safari/Chrome, other WebKit */ /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  margin-left: auto;
  margin-right: auto;
}

.about-card-top-section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 20px;
}

.about-title {
  margin-top: auto;
  margin-bottom: auto;
}
.about-title-row {
  font-family: "Amatic SC", cursive;
  font-weight: 700;
  font-size: 3.2em;
  margin: 0px;
  text-align: left;
}

.about-image {
  width: 200px;
  height: 200px;
  border-radius: 200px/200px;
  border-color: #080808;
  border-style: solid;
  border-width: 2px; /* Safari/Chrome, other WebKit */ /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.about-card-bottom-section{
    margin: 0px 20px 20px 20px;
    text-align: justify;
    text-justify: inter-word;
    font-size: 0.9em;
    font-family: "Raleway", sans-serif;
    text-indent: 2em;
    font-weight: 500;
}


@media (max-width: 1024px) {
  .about-card-top-section {
    flex-direction: column;
    justify-content: center;
  }
  .about-title {
    margin-left: auto;
    margin-right: auto;
  }
  .about-title-row {
    text-align: center;
  }
  .about-card{
    margin-top: 60px;
    margin-bottom: 40px;

  }
}

.education-page {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.education-card {
  width: 500px;
  max-width: 100%;
  border-color: #080808;
  border-style: solid;
  border-width: 2px; /* Safari/Chrome, other WebKit */ /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.education-card-top-section {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 20px;

}

.education-title {
  font-family: "Amatic SC", cursive;
  font-weight: 700;
  font-size: 3.2em;
  margin: 0px;
  text-align: center;
}
.education-subtitle {
  font-family: "Amatic SC", cursive;
  font-weight: 700;
  font-size: 2em;
  margin: 0px;
  text-align: center;
}

.education-year {
  font-family: "Amatic SC", cursive;
  font-weight: 700;
  font-size: 1.8em;
  margin: 0px;
  text-align: center;
}

.education-card-bottom-section {
  margin: 0px 20px 20px 20px;
  text-align: justify;
  text-justify: inter-word;
  font-size: 0.9em;
  font-family: "Raleway", sans-serif;
  text-indent: 2em;
}

.education-card-courses-section {
  margin: -10px 20px 20px 20px;
  text-align: left;
  font-size: 0.9em;
  font-family: "Raleway", sans-serif;
}

@media (max-width: 1024px) {
  .education-card{
    margin-top: 60px;
  }
  .education-card-top-section {
    flex-direction: column;
    justify-content: center;
  }
  .education-title {
    margin-left: auto;
    margin-right: auto;
  }
  .education-title-row {
    text-align: center;
  }
}

.experience-page {
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .experience-card {
    width: 500px;
    max-width: 100%;
    border-color: #080808;
    border-style: solid;
    border-width: 2px; /* Safari/Chrome, other WebKit */ /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .experience-cart-top-section {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .experience-title {
    font-family: "Amatic SC", cursive;
    font-weight: 700;
    font-size: 3.2em;
    margin: 0px;
    text-align: left;
  }
  .experience-subtitle {
    font-family: "Amatic SC", cursive;
    font-weight: 700;
    font-size: 2em;
    margin: 0px;
    text-align: center;
  }
  
  .experience-year {
    font-family: "Amatic SC", cursive;
    font-weight: 700;
    font-size: 1.8em;
    margin: 0px;
    text-align: center;
  }
  
  .experience-cart-bottom-section {
    margin: 0px 20px 20px 20px;
    text-align: justify;
    text-justify: inter-word;
    font-size: 0.9em;
    font-family: "Raleway", sans-serif;
    text-indent: 2em;
  }
  
  @media (max-width: 1024px) {
    .experience-card{
      margin-top: 60px;
      margin-bottom: 40px;
    }
    .experience-cart-top-section {
      flex-direction: column;
      justify-content: center;
    }
    .experience-title {
      margin-left: auto;
      margin-right: auto;
    }
    .experience-title-row {
      text-align: center;
    }
  }
  
.hardskills-page {
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .hardskills-card {
    width: 500px;
    max-width: 100%;
    border-color: #080808;
    border-style: solid;
    border-width: 2px; /* Safari/Chrome, other WebKit */ /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .hardskills-cart-top-section {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .hardskills-title {
    font-family: "Amatic SC", cursive;
    font-weight: 700;
    font-size: 3.2em;
    margin: 0px;
    text-align: left;
  }
  
  .hardskills-cart-bottom-section {
    margin: 0px 20px 20px 20px;
    text-align: left;
    font-size: 0.9em;
    font-weight: 500;
    font-family: "Raleway", sans-serif;
  }
  .hardskills-cart-bottom-section ul {
    padding-left:20px;
  }
  .hardskills-cart-bottom-section li {
    margin-top:5px;
  }

  @media (max-width: 1024px) {
    .hardskills-card{
      margin-top: 60px;
      margin-bottom: 40px;
    }
    .hardskills-cart-top-section {
      flex-direction: column;
      justify-content: center;
    }
    .hardskills-title {
      margin-left: auto;
      margin-right: auto;
    }
    .hardskills-title-row {
      text-align: center;
    }
  }
  
.hobbies-page {
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .hobbies-card {
    width: 500px;
    max-width: 100%;
    border-color: #080808;
    border-style: solid;
    border-width: 2px; /* Safari/Chrome, other WebKit */ /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .hobbies-cart-top-section {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .hobbies-title {
    font-family: "Amatic SC", cursive;
    font-weight: 700;
    font-size: 3.2em;
    margin: 0px;
    text-align: left;
  }
  
  .hobbies-cart-bottom-section {
    margin: 0px 20px 20px 20px;
    text-align: justify;
    text-justify: inter-word;
    font-size: 0.9em;
    font-family: "Raleway", sans-serif;
    text-indent: 2em;
  }
  
  @media (max-width: 1024px) {
    .hobbies-cart-top-section {
      flex-direction: column;
      justify-content: center;
    }
    .hobbies-title {
      margin-left: auto;
      margin-right: auto;
    }
    .hobbies-title-row {
      text-align: center;
    }
  }
  
.foreign-page {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.foreign-card {
  width: 400px;
  max-width: 100%;
  border-color: #080808;
  border-style: solid;
  border-width: 2px; /* Safari/Chrome, other WebKit */ /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.foreign-cart-top-section {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.foreign-title {
  font-family: "Amatic SC", cursive;
  font-weight: 700;
  font-size: 3.2em;
  margin: 0px;
  text-align: left;
}

.foreign-cart-bottom-section {
  margin: 0px 20px 20px 20px;
  font-family: "Raleway", sans-serif;
  
}

.foreign-language{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.foreign-language-title{
  font-size: 1em;
  font-weight: 600;
}
.foreign-language-level{
  font-size: 0.9em;
}

.language-progress-container {
  width: 100%;
  height: 7px;
  background-color: #a4a4a4;
  margin: 0.5em 0px 2em 0px;
}

.language-progress-content {
  height: 100%;
  background-color: #080808;
}

@media (max-width: 1024px) {
  .foreign-cart-top-section {
    flex-direction: column;
    justify-content: center;
  }
  .foreign-title {
    margin-left: auto;
    margin-right: auto;
  }
  .foreign-title-row {
    text-align: center;
  }
}

.projects-page {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.projects-card {
  width: 500px;
  max-width: 100%;
  border-color: #080808;
  border-style: solid;
  border-width: 2px; /* Safari/Chrome, other WebKit */ /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.projects-cart-top-section {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.projects-title {
  font-family: "Amatic SC", cursive;
  font-weight: 700;
  font-size: 3.2em;
  margin: 0px;
  text-align: left;
}

.projects-cart-bottom-section {
  margin: 0px 20px 20px 20px;
  font-family: "Raleway", sans-serif;
}

.projects-project {
  display: flex;
  flex-direction: column;
  text-align: justify;
  text-justify: inter-word;
}

.projects-project-title {
  font-weight: 600;
}

.projects-project-description {
  font-size: 0.9em;
  margin: 0px 1em 0px 1em;
}

.projects-project-repository {
  font-size: 0.9em;
  margin: 5px 1em 14px 1em;
  font-weight: 600;
  text-align: right;
}

@media (max-width: 1024px) {
  .projects-cart-top-section {
    flex-direction: column;
    justify-content: center;
  }
  .projects-title {
    margin-left: auto;
    margin-right: auto;
  }
  .projects-title-row {
    text-align: center;
  }
}

.softskills-page {
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .softskills-card {
    width: 500px;
    max-width: 100%;
    border-color: #080808;
    border-style: solid;
    border-width: 2px; /* Safari/Chrome, other WebKit */ /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .softskills-cart-top-section {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .softskills-title {
    font-family: "Amatic SC", cursive;
    font-weight: 700;
    font-size: 3.2em;
    margin: 0px;
    text-align: left;
  }
  
  .softskills-cart-bottom-section {
    margin: 0px 20px 20px 20px;
    text-align: justify;
    text-justify: inter-word;
    font-size: 0.9em;
    font-family: "Raleway", sans-serif;
    text-indent: 2em;
  }
  
  @media (max-width: 1024px) {
    .softskills-cart-top-section {
      flex-direction: column;
      justify-content: center;
    }
    .softskills-title {
      margin-left: auto;
      margin-right: auto;
    }
    .softskills-title-row {
      text-align: center;
    }
  }
  
.volunteering-page {
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .volunteering-card {
    width: 500px;
    max-width: 100%;
    border-color: #080808;
    border-style: solid;
    border-width: 2px; /* Safari/Chrome, other WebKit */ /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .volunteering-cart-top-section {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .volunteering-title {
    font-family: "Amatic SC", cursive;
    font-weight: 700;
    font-size: 3.2em;
    margin: 0px;
    text-align: center;
  }
  
  .volunteering-cart-bottom-section {
    margin: 0px 20px 20px 20px;
    text-align: justify;
    text-justify: inter-word;
    font-size: 0.9em;
    font-family: "Raleway", sans-serif;
    text-indent: 2em;
  }
  
  @media (max-width: 1024px) {
    .volunteering-card{
      margin-top: 60px;
    }
    .volunteering-cart-top-section {
      flex-direction: column;
      justify-content: center;
    }
    .volunteering-title {
      margin-left: auto;
      margin-right: auto;
    }
    .volunteering-title-row {
      text-align: center;
    }
  }
  
