.volunteering-page {
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .volunteering-card {
    width: 500px;
    max-width: 100%;
    border-color: #080808;
    border-style: solid;
    border-width: 2px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .volunteering-cart-top-section {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .volunteering-title {
    font-family: "Amatic SC", cursive;
    font-weight: 700;
    font-size: 3.2em;
    margin: 0px;
    text-align: center;
  }
  
  .volunteering-cart-bottom-section {
    margin: 0px 20px 20px 20px;
    text-align: justify;
    text-justify: inter-word;
    font-size: 0.9em;
    font-family: "Raleway", sans-serif;
    text-indent: 2em;
  }
  
  @media (max-width: 1024px) {
    .volunteering-card{
      margin-top: 60px;
    }
    .volunteering-cart-top-section {
      flex-direction: column;
      justify-content: center;
    }
    .volunteering-title {
      margin-left: auto;
      margin-right: auto;
    }
    .volunteering-title-row {
      text-align: center;
    }
  }
  