.about-page {
  background-color: white;
  min-height: 100vh;
  display:flex;
  align-items: center;
}

.about-card {
  width: 650px;
  max-width: 100%;
  border-color: #080808;
  border-style: solid;
  border-width: 2px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  margin-left: auto;
  margin-right: auto;
}

.about-card-top-section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 20px;
}

.about-title {
  margin-top: auto;
  margin-bottom: auto;
}
.about-title-row {
  font-family: "Amatic SC", cursive;
  font-weight: 700;
  font-size: 3.2em;
  margin: 0px;
  text-align: left;
}

.about-image {
  width: 200px;
  height: 200px;
  -moz-border-radius: 200px/200px;
  -webkit-border-radius: 200px 200px;
  border-radius: 200px/200px;
  border-color: #080808;
  border-style: solid;
  border-width: 2px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.about-card-bottom-section{
    margin: 0px 20px 20px 20px;
    text-align: justify;
    text-justify: inter-word;
    font-size: 0.9em;
    font-family: "Raleway", sans-serif;
    text-indent: 2em;
    font-weight: 500;
}


@media (max-width: 1024px) {
  .about-card-top-section {
    flex-direction: column;
    justify-content: center;
  }
  .about-title {
    margin-left: auto;
    margin-right: auto;
  }
  .about-title-row {
    text-align: center;
  }
  .about-card{
    margin-top: 60px;
    margin-bottom: 40px;

  }
}
