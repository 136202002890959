/* @import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Jost&family=Raleway:wght@600&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Jost&family=Raleway:wght@200;300;400;500;600;700&display=swap');

.App {
  width: 100%;
  height: 100vh;
  background-color: white;
}

body {
  margin: 0;
  padding: 0;
}

.content {
  width: 75%;
  float: right;
  text-align: center;
  padding-left: 2em;
  padding-right: 2em;
}

@media (max-width: 1024px) {
  .content {
    width: 90%;
    float: none;
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
    /* margin-top: 50px; */
    margin-left: auto;
    margin-right: auto;
  }
}

.continutprovizoriu {
  /* position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center; */
}
