.projects-page {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.projects-card {
  width: 500px;
  max-width: 100%;
  border-color: #080808;
  border-style: solid;
  border-width: 2px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.projects-cart-top-section {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.projects-title {
  font-family: "Amatic SC", cursive;
  font-weight: 700;
  font-size: 3.2em;
  margin: 0px;
  text-align: left;
}

.projects-cart-bottom-section {
  margin: 0px 20px 20px 20px;
  font-family: "Raleway", sans-serif;
}

.projects-project {
  display: flex;
  flex-direction: column;
  text-align: justify;
  text-justify: inter-word;
}

.projects-project-title {
  font-weight: 600;
}

.projects-project-description {
  font-size: 0.9em;
  margin: 0px 1em 0px 1em;
}

.projects-project-repository {
  font-size: 0.9em;
  margin: 5px 1em 14px 1em;
  font-weight: 600;
  text-align: right;
}

@media (max-width: 1024px) {
  .projects-cart-top-section {
    flex-direction: column;
    justify-content: center;
  }
  .projects-title {
    margin-left: auto;
    margin-right: auto;
  }
  .projects-title-row {
    text-align: center;
  }
}
