.foreign-page {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.foreign-card {
  width: 400px;
  max-width: 100%;
  border-color: #080808;
  border-style: solid;
  border-width: 2px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.foreign-cart-top-section {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.foreign-title {
  font-family: "Amatic SC", cursive;
  font-weight: 700;
  font-size: 3.2em;
  margin: 0px;
  text-align: left;
}

.foreign-cart-bottom-section {
  margin: 0px 20px 20px 20px;
  font-family: "Raleway", sans-serif;
  
}

.foreign-language{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.foreign-language-title{
  font-size: 1em;
  font-weight: 600;
}
.foreign-language-level{
  font-size: 0.9em;
}

.language-progress-container {
  width: 100%;
  height: 7px;
  background-color: #a4a4a4;
  margin: 0.5em 0px 2em 0px;
}

.language-progress-content {
  height: 100%;
  background-color: #080808;
}

@media (max-width: 1024px) {
  .foreign-cart-top-section {
    flex-direction: column;
    justify-content: center;
  }
  .foreign-title {
    margin-left: auto;
    margin-right: auto;
  }
  .foreign-title-row {
    text-align: center;
  }
}
