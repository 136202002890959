.education-page {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.education-card {
  width: 500px;
  max-width: 100%;
  border-color: #080808;
  border-style: solid;
  border-width: 2px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.education-card-top-section {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 20px;

}

.education-title {
  font-family: "Amatic SC", cursive;
  font-weight: 700;
  font-size: 3.2em;
  margin: 0px;
  text-align: center;
}
.education-subtitle {
  font-family: "Amatic SC", cursive;
  font-weight: 700;
  font-size: 2em;
  margin: 0px;
  text-align: center;
}

.education-year {
  font-family: "Amatic SC", cursive;
  font-weight: 700;
  font-size: 1.8em;
  margin: 0px;
  text-align: center;
}

.education-card-bottom-section {
  margin: 0px 20px 20px 20px;
  text-align: justify;
  text-justify: inter-word;
  font-size: 0.9em;
  font-family: "Raleway", sans-serif;
  text-indent: 2em;
}

.education-card-courses-section {
  margin: -10px 20px 20px 20px;
  text-align: left;
  font-size: 0.9em;
  font-family: "Raleway", sans-serif;
}

@media (max-width: 1024px) {
  .education-card{
    margin-top: 60px;
  }
  .education-card-top-section {
    flex-direction: column;
    justify-content: center;
  }
  .education-title {
    margin-left: auto;
    margin-right: auto;
  }
  .education-title-row {
    text-align: center;
  }
}
