.certifications-page {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.certifications-card {
  width: 500px;
  max-width: 100%;
  border-color: #080808;
  border-style: solid;
  border-width: 2px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.certifications-cart-top-section {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.certifications-title {
  font-family: "Amatic SC", cursive;
  font-weight: 700;
  font-size: 3.2em;
  margin: 0px;
  text-align: left;
}

.certifications-cart-bottom-section {
  margin: 0px 20px 20px 20px;
  font-family: "Raleway", sans-serif;
}

.certifications-certificate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.certifications-certificate-titles {
  font-size: 1em;
  font-weight: 600;
  
}
.certifications-certificate-title {
  margin: 0px;
  display: flex;
  flex-direction: row;
  text-align: left;
}
.certifications-certificate-level {
  font-size: 0.9em;
}

.certifications-certificate-issuer {
  display: flex;
  flex-direction: row;
  text-align: left;
  font-size: 0.9em;
  margin: 0px 0px 7px 1em;
}
@media (max-width: 1024px) {
  .certifications-card{
    margin-top: 60px;
    margin-bottom: 40px;
  }
  .certifications-cart-top-section {
    flex-direction: column;
    justify-content: center;
  }
  .certifications-title {
    margin-left: auto;
    margin-right: auto;
  }
  .certifications-title-row {
    text-align: center;
  }
}
